/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-3",
    "aws_appsync_graphqlEndpoint": "https://w3sg2boslvfgjoq6yqzfihwz2q.appsync-api.eu-west-3.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-3",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-oay4wlb4frf57ayl46nofwx324",
    "aws_cognito_identity_pool_id": "eu-west-3:72a0495c-caf2-459c-936d-5a43b6e2a198",
    "aws_cognito_region": "eu-west-3",
    "aws_user_pools_id": "eu-west-3_FXLgb7gd4",
    "aws_user_pools_web_client_id": "247760lm8rilbl0s9kk0alus0l",
    "oauth": {
        "domain": "tgx90utxwtwg-staging.auth.eu-west-3.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000,https://visite.smart-renting.com,https://www.visite.smart-renting.com,https://dev.visite.smart-renting.com,https://preproduction.visite.smart-renting.com,https://staging.visite.smart-renting.com",
        "redirectSignOut": "http://localhost:3000,https://visite.smart-renting.com,https://www.visite.smart-renting.com,https://dev.visite.smart-renting.com,https://preproduction.visite.smart-renting.com,https://staging.visite.smart-renting.com",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "visitef9d20b207c8942be9343ff644eb475ef120806-staging",
    "aws_user_files_s3_bucket_region": "eu-west-3"
};


export default awsmobile;
